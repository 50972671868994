import React from 'react';
import Homepage from './Homepage';
import './App.css';

function App() {
    return (
        <Homepage />
  );
}

export default App;
